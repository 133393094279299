// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/app/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import CrownOutlined from '/app/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import TableOutlined from '/app/node_modules/@ant-design/icons/es/icons/TableOutlined';
import SettingOutlined from '/app/node_modules/@ant-design/icons/es/icons/SettingOutlined';
import MailOutlined from '/app/node_modules/@ant-design/icons/es/icons/MailOutlined';
import AreaChartOutlined from '/app/node_modules/@ant-design/icons/es/icons/AreaChartOutlined';
export default { SmileOutlined, CrownOutlined, TableOutlined, SettingOutlined, MailOutlined, AreaChartOutlined };
