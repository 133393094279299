// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"login","path":"/user/login","parentId":"1","id":"2"},"3":{"path":"/dashboard/welcome","name":"welcome","icon":"smile","parentId":"ant-design-pro-layout","id":"3"},"4":{"path":"/admin","name":"admin","icon":"crown","access":"canAdmin","parentId":"ant-design-pro-layout","id":"4"},"5":{"path":"/admin","redirect":"/admin/sub-page","parentId":"4","id":"5"},"6":{"path":"/admin/sub-page","name":"sub-page","parentId":"4","id":"6"},"7":{"path":"/number-pool","name":"number-pool","icon":"table","parentId":"ant-design-pro-layout","id":"7"},"8":{"path":"/number-pool","redirect":"/number-pool/list","parentId":"7","id":"8"},"9":{"path":"/number-pool/list","name":"list","parentId":"7","id":"9"},"10":{"path":"/number-pool/list/:poolId","name":"pool-sender","hideInMenu":true,"parentId":"7","id":"10"},"11":{"path":"/email-service","name":"email-service","icon":"setting","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"11"},"12":{"path":"/email-service","redirect":"/email-service/guide","parentId":"11","id":"12"},"13":{"path":"/email-service/guide","name":"guide","parentId":"11","id":"13"},"14":{"path":"/email-task","name":"email-task","icon":"mail","parentId":"ant-design-pro-layout","id":"14"},"15":{"path":"/email-task","redirect":"/email-task/list","parentId":"14","id":"15"},"16":{"path":"/email-task/list","name":"list","parentId":"14","id":"16"},"17":{"path":"https://metabase.turbomx.org","target":"_blank","name":"analysis","icon":"AreaChartOutlined","parentId":"ant-design-pro-layout","id":"17"},"18":{"path":"/","redirect":"/dashboard/welcome","parentId":"ant-design-pro-layout","id":"18"},"19":{"path":"*","layout":false,"id":"19"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Dashboard__Welcome" */'@/pages/Dashboard/Welcome.tsx')),
'4': React.lazy(() => import('./EmptyRoute')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Admin" */'@/pages/Admin.tsx')),
'7': React.lazy(() => import('./EmptyRoute')),
'8': React.lazy(() => import('./EmptyRoute')),
'9': React.lazy(() => import(/* webpackChunkName: "p__NumberPool__List__index" */'@/pages/NumberPool/List/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__NumberPool__PoolSender__index" */'@/pages/NumberPool/PoolSender/index.tsx')),
'11': React.lazy(() => import('./EmptyRoute')),
'12': React.lazy(() => import('./EmptyRoute')),
'13': React.lazy(() => import(/* webpackChunkName: "p__EmailService__index" */'@/pages/EmailService/index.tsx')),
'14': React.lazy(() => import('./EmptyRoute')),
'15': React.lazy(() => import('./EmptyRoute')),
'16': React.lazy(() => import(/* webpackChunkName: "p__EmailTask__List__index" */'@/pages/EmailTask/List/index.tsx')),
'17': React.lazy(() => import('./EmptyRoute')),
'18': React.lazy(() => import('./EmptyRoute')),
'19': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/app/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
